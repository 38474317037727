import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: require('./@launchpad/core/locales/translated/en/translation.json')
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    returnNull: false,
    nsSeparator: ':::',
    keySeparator: '::'
  });

i18n.language = 'en';

const lang = localStorage.getItem('LOCALE');
if (lang) {
  i18n.changeLanguage(lang);
} else {
  i18n.changeLanguage('en');
  // i18n.changeLanguage('it');
}

// if (i18n.language.split('-').length > 1) {
//   i18n.changeLanguage('en');
// }

export default i18n;
